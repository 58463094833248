import { Fragment, useState } from 'react';
import { Button } from 'antd';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSuspenseQuery, useReadQuery } from '@apollo/client';

import { apolloClient, apolloCache } from 'src/graphql';
import { TextBlock, ModalProceed } from 'src/components/Misc';
import { JourneyModalMain, JourneyModalFooter } from 'src/components/Journey/ModalLayout';
import { BackupEmpty } from 'src/components/Backup/Empty';
import { ProfileDocument, BackupReadyIntegrationsDocument } from 'src/graphql/schema';
import { IntegrationRadioGroup as BackupPlatformRadioGroup } from 'src/components/Integration/Radio';

import type { IBackupOutletContext } from 'src/pages/Backups';
import type {
  IOauthIntegration,
  IProfileQuery,
  IBackupReadyIntegrationsQuery,
  IBackupsQuery,
} from 'src/graphql/schema';

const resolvers = {
  OauthIntegration: {
    isClientOwner({ creator }: { creator: { id: string } }) {
      const dataProfile = apolloCache.readQuery<IProfileQuery>({ query: ProfileDocument });

      if (!dataProfile) return false;

      return creator?.id === dataProfile.profile.id;
    },
  },
};

const BackupPlatformNewContainer = () => {
  apolloClient.addResolvers(resolvers);

  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get('i');

  const [selectedIntegrationId, setSelectedIntegrationId] = useState(integrationId ?? '');
  const [isModalProceedOpen, setIsModalProceedOpen] = useState(false);
  const navigate = useNavigate();
  const { queryRef } = useOutletContext<IBackupOutletContext>();

  useReadQuery<IBackupsQuery>(queryRef);
  useSuspenseQuery(ProfileDocument);
  const { data } = useSuspenseQuery<IBackupReadyIntegrationsQuery>(BackupReadyIntegrationsDocument, {
    fetchPolicy: 'network-only',
    variables: {
      page: 1,
      perPage: 100,
    },
  });

  const onSave = () => navigate(`/backups/new/settings?i=${selectedIntegrationId}`);

  const onConnect = () => navigate('/integrations');

  const filteredIntegrations =
    data.backupReadyIntegrations.nodes.filter((backup) => {
      const { isClientOwner, backupable } = backup as IOauthIntegration;

      return backupable || !isClientOwner;
    }) ?? [];

  return (
    <Fragment>
      <JourneyModalMain className="ck-backup__main">
        <div className="d-flex justify-content-between align-items-end ck-backup__new-header">
          <TextBlock
            as="h2"
            titleColor="light-blue"
            title={filteredIntegrations.length ? 'Select Platform' : 'Connect Platform'}
            text="Please choose the platform for your backup from the options below."
          />
          {Boolean(filteredIntegrations.length) && (
            <Button type="primary" onClick={() => setIsModalProceedOpen(true)}>
              Connect New Platform
            </Button>
          )}
        </div>

        {filteredIntegrations.length ? (
          <div className="ck-backup__container mt-4 mb-32">
            <BackupPlatformRadioGroup
              items={filteredIntegrations.map((item) => {
                const { id, provider, accountName, isClientOwner } = item as IOauthIntegration;
                return {
                  id,
                  platform: provider,
                  name: accountName || '',
                  disabled: !isClientOwner,
                };
              })}
              onClick={(id) => setSelectedIntegrationId(id)}
              selectedItemId={selectedIntegrationId}
              heading="Connected integrations"
            />
          </div>
        ) : (
          <BackupEmpty type="connect" className="mt-50" />
        )}
      </JourneyModalMain>

      <JourneyModalFooter justify="end">
        <JourneyModalFooter.Item>
          <Button
            type="primary"
            disabled={!filteredIntegrations.length || !selectedIntegrationId || selectedIntegrationId === integrationId}
            onClick={onSave}
          >
            Save & Next
          </Button>
        </JourneyModalFooter.Item>
      </JourneyModalFooter>

      <ModalProceed
        isOpen={isModalProceedOpen}
        title="Connect new platform"
        text="Connecting new platform should be done in Integrations page, would you like to open it?"
        proceedText="Open Integrations Page"
        onProceed={onConnect}
        onCancel={() => setIsModalProceedOpen(false)}
      />
    </Fragment>
  );
};

export default BackupPlatformNewContainer;
